import React from "react";
import theme from "theme";
import { Theme, Span, Text, Link, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Elite Fußball Fields
			</title>
			<meta name={"description"} content={"Erleben Sie die beste Fußballplatzvermietung der Stadt!"} />
			<meta property={"og:title"} content={"Home | Elite Fußball Fields"} />
			<meta property={"og:description"} content={"Erleben Sie die beste Fußballplatzvermietung der Stadt!"} />
			<link rel={"shortcut icon"} href={"https://radiancos.com/img/4637899231.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://radiancos.com/img/4637899231.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://radiancos.com/img/4637899231.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://radiancos.com/img/4637899231.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://radiancos.com/img/4637899231.png"} />
			<meta name={"msapplication-TileImage"} content={"https://radiancos.com/img/1.jpg"} />
			
		</Helmet>
		<Components.Header />
		<Section min-height="100vh" padding="0 0 0 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="none"
				width="100%"
				sm-padding="72px 0px 0px 0px"
			/>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 50px 0px"
				margin="40px 5% 40px 5%"
				lg-padding="24px 60px 24px 60px"
				sm-padding="0px 20px 0px 20px"
				padding="24px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					<Span color="--green">
					Elite  {" "}
					</Span>
					Fußball Fields
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead" lg-text-align="center">
				Wir bei Elite Fußball Fields glauben, dass Fußball mehr als nur ein Spiel ist – es ist eine Leidenschaft, die Menschen zusammenbringt. Unsere hochmodernen Fußballplätze bieten ein erstklassiges Spielerlebnis und stellen sicher, dass Sie die beste Umgebung haben, um Ihren Lieblingssport zu genießen. Wir sind bestrebt, erstklassige Einrichtungen und außergewöhnlichen Service bereitzustellen, damit jeder Besuch unvergesslich wird. Kommen Sie und erleben Sie die Freude am Fußball an einem Ort, an dem das Spiel wirklich verstanden wird.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/contacts"
						padding="12px 54px 12px 54px"
						color="--light"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						background="--color-green"
						hover-background="#1e6856"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Nehmen Sie Kontakt auf
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				background="#FFFFFF url(https://radiancos.com/img/1.jpg) center center/cover no-repeat scroll padding-box"
				lg-min-height="400px"
			/>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				lg-display="flex"
				lg-justify-content="center"
				padding="0px 16px 0px 0px"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(9, 60px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px"
					max-width="480px"
					lg-margin="0px 0px 16px 0px"
					sm-grid-template-rows="repeat(9, 10vw)"
				>
					<Image
						src="https://radiancos.com/img/2.jpg"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-row="1 / span 5"
						grid-column="1 / span 1"
						height="100%"
					/>
					<Image
						src="https://radiancos.com/img/3.jpg"
						border-radius="24px"
						object-fit="cover"
						grid-column="2 / span 1"
						grid-row="2 / span 3"
						width="100%"
						height="100%"
					/>
					<Image
						src="https://radiancos.com/img/4.jpg"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="1 / span 1"
						grid-row="6 / span 3"
						height="100%"
					/>
					<Image
						src="https://radiancos.com/img/5.jpg"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="2 / span 1"
						grid-row="5 / span 5"
						height="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="20px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				lg-width="100%"
				lg-padding="24px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Warum Elite Fußball Fields wählen?
				</Text>
				<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				Hochwertige Einrichtungen: Unsere Plätze sind mit der neuesten Technologie konzipiert, um eine hervorragende Spieloberfläche zu bieten, die die Leistung verbessert und das Verletzungsrisiko verringert.
					<br />
					<br />
					Bequeme Buchung: Wir bieten einen einfachen und flexiblen Buchungsprozess, mit dem Sie Ihre bevorzugten Zeitfenster mühelos reservieren können.
					<br />
					<br />
					Ausgezeichnete Lage: Elite Fußball Fields befindet sich in erstklassiger Lage und ist von allen Teilen der Stadt aus leicht zu erreichen.
					<br />
					<br />
					Professionelles Personal: Unser freundliches und sachkundiges Personal steht Ihnen jederzeit zur Verfügung, um Ihnen zu helfen und sicherzustellen, dass Ihr Erlebnis außergewöhnlich ist.

				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://radiancos.com/img/6.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://radiancos.com/img/7.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Elite Fußball Fields hat sich verpflichtet, die höchsten Standards bei der Vermietung von Fußballplätzen zu erfüllen. Unsere Plätze werden sorgfältig gepflegt, um das ganze Jahr über optimale Spielbedingungen zu gewährleisten. Wir richten uns an Einzelpersonen, Teams und Organisationen, die einen zuverlässigen und professionellen Veranstaltungsort zum Trainieren, Wettkämpfen oder Ausrichten von Veranstaltungen suchen. 
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
			Kommen Sie mit aufs Feld!
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			Verpassen Sie nicht das beste Fußballerlebnis der Stadt. Egal, ob Sie nur zum Spaß oder als Wettkampfspieler spielen möchten, Elite Fußball Fields bietet alles, was Sie brauchen, um das Spiel zu genießen. Buchen Sie jetzt Ihre Sitzung und sehen Sie, warum wir die erste Wahl für Fußballfans sind.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				href="/contacts"
				type="link"
				text-decoration-line="initial"
				background="--color-green"
			>
				Kontakte
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});